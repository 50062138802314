import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import posed from "react-pose"
import Layout from "../components/layout"
import styles from "../styles/home.module.css"

const Box = posed.div({
  closed: { y: "300px" },
  open: { y: "0px", transition: { duration: 600 } },
})

export default ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Gaston Hummel</title>
    </Helmet>
    <Box className="container" initialPose="closed" pose="open">
      <h1 className="center"> Gaston Hummel</h1>
      <p className="center">
        Welcome to my personal website on which I share videos of my
        Toastmasters speeches, blogs and insights from my favourite books.
      </p>
      <div className="sections">
        <div>
          <Link to="/speeches/">
            <button className={styles.button}>Speeches</button>
          </Link>
        </div>
        <div>
          <Link to="/blogs/">
            <button className={styles.button}>Blog</button>
          </Link>
        </div>
        <div>
          <Link to="/books/">
            <button className={styles.button}>Books</button>
          </Link>
        </div>
        <div>
          <Link to="/contact/">
            <button className={styles.button}>Contact</button>
          </Link>
        </div>
      </div>
    </Box>
  </Layout>
)
